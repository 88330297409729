<template>
<v-container id="printMe">
  <v-row >
    <v-col cols="12" >

      <v-card :loading="loading" class="elevation-4 transparent" >
        <v-row justify="center">

            <v-col cols="12"  md="3" sm="3" xs="12" >
              <v-img :src="logo" alt="alt"  contain max-height="60"></v-img>
            </v-col>

            <v-col cols="12" md="4" sm="4" xs="12">

                <h3 class="red--text">{{tipo}} {{Docum.Numdoc}}</h3>
                <h3 class="font-weight-black"> Fecha: {{ Docum.Fecha}}</h3>

            </v-col>
            <v-col cols="12" md="5" sm="5" xs="12">

              <!-- <v-btn small class="mx-2" color="info"
                @click="regresar">
                <v-icon color="white">reply</v-icon>
              </v-btn> -->

<!--              <v-btn small class="mx-2" color="info"
                @click="imprimir">
                <v-icon color="white">print</v-icon>
              </v-btn>
 -->

              <v-btn small
                v-if="leyendaStatus ==''"
                color="success"
                @click="order" >
                Seleccionar Forma de pago
              </v-btn>

<!--              <v-btn
                small
                color="secondary"
                value="Print this page" >
                <v-icon color="white"
                @click="mostrarComprobante">print</v-icon>
              </v-btn>
 -->

              

            </v-col>

          </v-row>
        </v-card>
      </v-col>

    </v-row>

    <v-row>
      <!-- DATOS DEL DOCUMENTO Y DEL CLIENTE-->
      <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card >
          <v-card-text>
            <div class="text-xs-left font-weight-black"><strong>CLIENTE: {{ Clientes.Nomcli }}</strong></div>
            <div class="text-xs-left red--text font-weight-black "> RFC {{ Clientes.Rfc }}</div>
              <div class="text-xs-left ">DIRECCIÓN: {{ Clientes.Calle + ' ' + Clientes.Numext + ' ' + Clientes.Colonia }} </div>
              <div>{{Clientes.Ciudad + ' ' + Clientes.Estado }}  CP {{Clientes.Cp}}</div>
               <div class="text-xs-left ">{{ Clientes.Email1 }}</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12" >

        <v-card class="ma-0">
          <v-card-text dense class="ma-0">
            <h2 class="red--text text-center"> {{ leyendaStatus }}</h2>
            <v-radio-group dense v-model="radios" :mandatory="false" row readonly>
              <v-radio v-if="radios==1" label="Recoger en tienda" value="1" color="primary"></v-radio>
              <v-radio v-if="radios==2"  label="Envío a domicilio" value="2" color="primary"></v-radio>
            </v-radio-group>

            <!-- nombre {{select.nombre}}} -->
            <v-select
              v-if ="radios==2"
              v-model="select.nombre"
              :hint="` ${select.numclisuc} ${select.Calle} ${select.Numext} ${select.colonia} ${select.Ciudad}
              ${select.Estado} CP ${select.Cp} Tel. ${select.telefono} `"
              :items="getDirecciones"
              item-text="select.nombre"
              item-value="select.numclisuc"
              :label="select.nombre"
              prepend-icon="house"
              persistent-hint
              return-object
              readonly
            ></v-select>

          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-row>
      <!-- TABLA DE PARTIDAS  -->
      <v-col cols="12" xs="12" class="ma-1 pa-1">
        <v-card elevation="10" class="mb-4">

          <v-data-table
          :headers="headers"
          :items="MovinArray"
          :items-per-page="longitud"
          fixed-header

          class="elevation-1 pa-0 text-xs-center"
          hide-default-footer
          >

          </v-data-table>
        </v-card>

      </v-col>
    </v-row>

    <v-row>
      <!-- DATOS DE LA EMPRESA -->
      <v-col cols="6" xl="6" lg="6" md="6" sm="6" xs="12">
        <v-card >
            <v-card-text>
              <div class="text-xs-left blue--text font-weight-black">{{ getCIA.nomcia }}</div>
              <div class="text-xs-left red--text "><strong>{{ getCIA.rfccia }}</strong></div>
              <div class="text-xs-left ">{{ getCIA.calle + ' ' + getCIA.numext + ' ' + getCIA.colonia  }}</div>
              <div class="text-xs-left ">{{  getCIA.ciudad + ', ' + getCIA.estado + ' CP: ' + getCIA.cp}}</div>
            </v-card-text>
        </v-card>
      </v-col>

      <!-- {{mostrarPreciosNetos}} -->
      <v-col cols="12"  xl="6" lg="6" md="5" sm="5" xs="12"  >
        <v-card elevation="10">
          <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="titulo in titulos"
                    :key="titulo.name"
                  >
                    <td>{{ titulo.name }}</td>
                    <td class="text-end">{{ titulo.valor }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
// import autoTable from 'jspdf-autotable'
// import Impresion from '@/views/pedidos/Impresion.vue'
import tempMovin from '@/mixins/tempMovin.js'

var accounting = require('accounting')
var base64 = require('base-64')

export default {
  mixins: [tempMovin],
  // components:{
  //  Impresion
  // },

  data () {
    return {
      mostrarPreciosNetos: false,
      leyendaStatus: '',
      select: {
        nombre: '',
        idweb: '',
        encargado: '',
        Calle: '',
        Numext: '',
        colonia: '',
        Ciudad: '',
        Estado: '',
        Cp: '',
        telefono: '',
        numclisuc: ''
      },
      logo: '',
      Importe: 0,
      Descuento: 0,
      Subtotal: 0,
      Iva: 0,
      Total: 0,

      MovinArray: [],
      tipodoc: '',
      loading: true,
      Clientes: {},
      Movim: [],
      Docum: '',
      radios: '1',
      dialog: false,
      total: '',
      direccion: '',

      comprobanteModal: 0,
      tipo: '',
      longitud: 5,

      headers: [
        // { text: 'ID'         ,align: 'center', value: 'Numpar'      ,sortable: false },
        { text: 'Clave', align: 'left', value: 'Numart', sortable: false },
        { text: 'Descripción', align: 'left', value: 'Descrip', sortable: false },
        { text: 'Cantidad', align: 'center', value: 'Cant', sortable: false },
        { text: 'Unidad', align: 'center', value: 'Unidad', sortable: false },
        { text: 'Precio', align: 'right', value: 'Precio', sortable: false },
        { text: 'Descuento', align: 'right', value: 'Descuento', sortable: false },
        // { text: 'Impuesto'  ,align: 'right',  value: 'Impuesto1'   ,sortable: false },
        // { text: 'Importe'    ,align: 'right',  value: 'Importe'     ,sortable: false },
        // { text: 'Subtotal'   ,align: 'right',  value: 'Subtotal'    ,sortable: false },
        { text: 'Precio Neto', align: 'right', value: 'Total', sortable: false }
      ],
      titulos: []
    }
  },

  created () {
    this.logo = this.getLogourl
    // REVISAR PRECIOS NETOS
    if (this.getConfig.preciosnetos == '1') {
      this.mostrarPreciosNetos = true
    }
    if (this.getConfig.preciosnetos == '0') {
      this.mostrarPreciosNetos = false
    }
    
    this.loading = true
    
    let iddocumparam = ''

    console.log("get documento",this.getDocumento)
    // this.Iddocum = this.getDocumento.iddocum
    console.log("router params", this.$route.params)

    if (this.$route.params.info != undefined) {
      // si no manda parametros rechaazar
      // console.log("decode", this.$route.params.info)
      iddocumparam = this.$route.params.info.iddocum
      console.log("tiposoc si no manda paramertos 1 info.iddocum",iddocumparam)
      // iddocumparam = this.getDocumento.Iddocum

     
      // RUTA PARA CUANDO biene de la vista carrito.
      if (this.$route.params.iddocum != undefined) {
        iddocumparam = this.$route.params.iddocum
      }


    } else {
      // console.log("getDocumento", this.getDocumento)
      iddocumparam = this.getDocumento.Iddocum
    }



    if (iddocumparam == undefined){
      iddocumparam = this.getDocumento.iddocum
    }
    console.log("iddocumparam iddocum",iddocumparam)


    this.init(iddocumparam)
  },

  computed: {
    ...mapGetters('carrito', ['getCarrito', 'getTC']),
    ...mapGetters('documento', ['getDocumento']),
    ...mapGetters('Login', ['getdatosUsuario', 'getClientes', 'getCliente', 'getCIA', 'getModo']),
    ...mapGetters('direccion', ['getDirecciones', 'getDireccion']),
    ...mapGetters('config', ['getConfig']),
    ...mapGetters('tema', ['getLogourl']),

  },

  methods: {
    ...mapActions('documento', ['addTotales', 'addDocumento']),
    ...mapActions('direccion', ['traerDirecciones']),

    //  imprimir(){
    //    console.log("PRINT ME")
    // // Pass the element id here
    // // this.$htmlToPaper('printMe', null, () => {
    // //   console.log('Printing completed or was cancelled!');
    // // })

    // //NO SE VE BIEN. No carga vuetify
    // this.$htmlToPaper('printMe', { /* local options */ });

    //  },

    mostrarComprobante () {
      this.$router.push({ name: 'impresion' }).catch(err => { console.log(err) })
    },

    regresar () {
      // this.$router.push({name:'consultadoc'}).catch(err => {console.log(err)})
      this.$router.back()
    },

    init (tipodoc) {

      this.loading = true
      // console.log(this.getdatosUsuario.numcli)
      this.traerDirecciones(this.getdatosUsuario.numcli).then(response => {
        // console.log("direcciones carrito", response)
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)

      // this.getDirecciones

      this.$http.get('api/v1/order.info/' + tipodoc).then(response => {
        // console.log(response)

        response.body.Importe = accounting.unformat(response.body.Importe)
        response.body.Impuesto1 = accounting.unformat(response.body.Impuesto1)
        response.body.Descuento = accounting.unformat(response.body.Descuento)

        this.total = accounting.formatNumber((parseFloat(response.body.Importe) - parseFloat(response.body.Descuento) + parseFloat(response.body.Impuesto1)), 2)

        response.body.Importe = accounting.formatNumber(response.body.Importe, 2)
        response.body.Impuesto1 = accounting.formatNumber(response.body.Impuesto1, 2)
        response.body.Descuento = accounting.formatNumber(response.body.Descuento, 2)

        this.Docum = response.body
        //
        if (response.body.Clientes != null) {
          this.Clientes = response.body.Clientes[0]
        }
        this.Movim = response.body.Movim

        this.crearArreglo()

        // Cargar direnvio
        // console.log("DOCUM",this.Docum)

        

        if (this.Docum.Tipodoc == ' P') {
          this.tipo = 'Pedido'
        } else {
          this.tipo = 'Cotización'
        }

        if (this.Docum.Status == 9) {
          // console.log("No se puede pagar una cotización procesada")
          this.leyendaStatus = 'PEDIDA'
        }

        if (this.Docum.Status == 0 && this.tipo == 'Pedido') {
          // console.log("No se puede pagar una cotización procesada")
          this.leyendaStatus = 'PEDIDO PROCESADO'
        }

        if (this.Docum.Direnvio.trim() != '' && this.Docum.Numclisuc != '') {
          // console.log("getnumclisuc", this.Docum)
          this.radios = '2'
          this.Docum.numclisuc
          var cNumclisuc = this.Docum.numclisuc.trim()

          this.$http.get('auth/api/v1/getnumclisuc/' + cNumclisuc).then(response => {
            // console.log('numclisuc estado', response)
            // this.idweb= response.data.idweb
            this.nombresuc = response.data.nombre
            this.select = response.data
          })
        } else {
          this.radios = '1'
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
    },

    crearArreglo () {
      // console.table (this.Movim)
      this.Movim.forEach(element => {
        element.Precio = accounting.unformat(element.Precio)
        element.Impuesto1 = accounting.unformat(element.Impuesto1)
        element.Impuesto2 = accounting.unformat(element.Impuesto2)
        element.Importe = accounting.formatNumber(element.Cant * accounting.unformat(element.Precio), 2)
        element.Iva = 0
        element.Total = 0
      })

      this.MovinArray = this.Movim
      this.longitud = this.MovinArray.length

      // console.log("crearArreglo")
      this.formarMovim(this.Movim)

      // Metódo para formar la tabla de totales.
      this.tablaTotales()
    },

    tablaTotales () {
      if (this.mostrarPreciosNetos=== true){
        this.titulos = [
          { name: 'Divisa', valor: 'Pesos' },
          { name: 'Importe', valor: this.Importe },
          { name: 'Descuento', valor: this.Descuento },
          { name: 'Total', valor: this.Total }
        ]

      } else {
        this.titulos = [
          { name: 'Divisa', valor: 'Pesos' },
          { name: 'Importe', valor: this.Importe },
          { name: 'Descuento', valor: this.Descuento },
          { name: 'Subtotal', valor: this.Subtotal },
          { name: 'Iva', valor: this.Iva },
          { name: 'Total', valor: this.Total }
        ]
      }
      // console.table(this.titulos)
    },

    // MANDAR IMPRIMIR
    order () {
      var totales = {
        importe: this.Importe,
        descuento: this.Descuento,
        subtotal: this.Subtotal,
        iva: this.Iva,
        total: this.Total
      }

      // console.log("order", totales)
      // Vuex
      this.addTotales(totales)

      this.$router.push({ name: 'tipopago', params: { tipo: '1' } }).catch(err => { console.log(err) })
    }
  }
}

</script>

<style>

  @media print{
      body,
           html {
             height: 5000px !important;
           }

           .scroll-y {
             height: 100% !important;
           }
         }

</style>
